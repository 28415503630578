import React from "react";
import Layout from "../layouts/index";
import BookImage from "../images/book-sentencePatterns1.png";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { ReactPhotoCollage } from "react-photo-collage";

class CourseDetails extends React.Component {
  render() {
    const setting = {
      width: "100%",
      height: ["500px", "170px"],
      layout: [1, 4],
      photos: [
        {
          src: "https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page1.png",
        },
        {
          src: "https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page2.png",
        },
        {
          src: "https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page3.png",
        },
        {
          src: "https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page4.png",
        },
        {
          src: "https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page5.png",
        },
      ],
      showNumOfRemainingPhotos: true,
    };
    return (
      <Layout>
        {/* Course info */}
        <div className="container-fluid bg-white pt-2 pt-md-0">
          <div className="container margin-from-header pt-6 pb-6">
            <div className="row d-flex justify-content-md-center">
              <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
                <h2>Basic Grammar and Sentence Patterns - Book 1</h2>
                <p>
                  This book series offers a new practical and effective way of
                  learning and communicating in Vietnamese, through essential
                  grammar understanding and sentence pattern practicing.
                </p>
                {/*<h1 className="text-success">$9.99</h1>*/}
                <div className="row pl-1 mt-2">
                  <button class="btn btn-solid-blue">
                    <OutboundLink href="https://gum.co/SAfl?wanted=true">
                      Buy now - 12.99
                    </OutboundLink>
                  </button>
                  <button class="btn btn-outline-blue mb-0 ml-md-2">
                    <OutboundLink
                      target="_blank"
                      href="https://amzn.to/2MnPy4o"
                    >
                      Buy on Amazon
                    </OutboundLink>
                  </button>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <img
                  src={BookImage}
                  className="img-fluid float-right rounded"
                  alt="Easy Vietnamese conversation"
                ></img>
              </div>
            </div>
          </div>
        </div>

        {/* Course contents */}
        <div className="container margin-from-header pb-6">
          <div className="row mb-6 justify-content-md-center">
            <div className="col-sm-12 col-md-8">
              <h2>What will you achieve?</h2>
              <ul>
                <li>
                  Learn basic Vietnamese pronunciation through a comprehensive
                  guide with Audio MP3s
                </li>
                <li>
                  Learn how to make basic Vietnamese sentences on your own
                </li>
                <li>
                  Understand Vietnamese grammar through 200 examples and
                  practices
                </li>
                <li>
                  Improve your listening with 40 minutes of Audio MP3s at both
                  slow and natural speeds
                </li>
                <li>
                  Practice along with a native speaker to improve your
                  pronunciation
                </li>
                <li>
                  Learn 250+ common Vietnamese words in context throughout the
                  book
                </li>
              </ul>
              <h2 className="pt-4">In this book, you'll find:</h2>
              <ul>
                <li>A full comprehensive pronunciation guide.</li>
                <li>Most essential grammar points for beginner level.</li>
                <li>
                  Most basic sentence patterns, explained and illustrated with
                  100 example sentences.
                </li>
                <li>
                  100 self-practice questions with answer key, allows you to
                  test your understanding after each introduced pattern.
                </li>
                <li>
                  {" "}
                  A Vietnamese-English word list with 250+ common vocabulary
                  words that are introduced throughout the book.
                </li>
                <li>
                  <b>40-minute audio recording</b> of Vietnamese pronunciation
                  (vowels, consonants, tones) and all sentence patterns' example
                  and practice sentences. Available for download at{" "}
                  <b>
                    <u>elingos.com/store</u>
                  </b>
                </li>
              </ul>
              <h2 className="pt-4">Book Preview</h2>
              <ReactPhotoCollage {...setting} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CourseDetails;
